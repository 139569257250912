<template>
  <b-modal ref="modal" centered hide-footer size="lg" :title="title">
    <b-card>
      <b-card-body>
        <b-row>
          <b-col lg="6" md="6">
            <inline-date-picker
              :id="controls.shift_date.id"
              :value-single="data.shift_date"
              :label="controls.shift_date.label"
              :readonly="controls.shift_date.readonly"
              :mode="$constants.FORM_MODE.EDIT"
              @changed="updateDateField"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <hr />
    <div class="form-row">
      <b-col sm="8"> </b-col>
      <b-col sm="4" class=" d-flex  justify-content-end">
        <b-button variant="outline-dark" class="m-1" @click="onSaveClick()">
          <b-spinner v-if="isLoading" small type="grow" />
          <font-awesome-icon v-if="!isLoading" icon="file" /> Save
        </b-button>

        <b-button variant="outline-dark" class="m-1" @click="onCloseClick()">
          <font-awesome-icon icon="times" /> Close
        </b-button>
      </b-col>
    </div>
  </b-modal>
</template>

<script>
import InlineDatePicker from "@/components/InlineDatePicker";

import shiftTemplateServices from "@/services/TrackTime/shift-templates.service";

import { mapState } from "vuex";

export default {
  props: {},
  name: "ShiftModal",
  components: {
    InlineDatePicker
  },
  data: function() {
    return {
      isLoading: false,
      title: "Shift templates duplication",
      mode: undefined,
      controls: {
        shift_date: {
          id: "datepicker:shift_date",
          label: "New templates will be valid from",
          changed: false,
          readonly: false
        }
      },
      data: {
        shift_date: "",
        items: []
      },
      groupedUsers: []
    };
  },
  computed: {
    ...mapState({
      profile: state => state.profile
    })
  },
  async mounted() {},
  methods: {
    show(payload) {
      this.data.items = payload;

      this.$refs.modal.show();
    },
    validateForm() {
      let errors = [];

      if (!this.data.shift_date) errors.push("Please select Shift Date");

      if (errors.length) {
        this.$form.makeToastError(errors[0]);
        return false;
      }
      return true;
    },
    async onSaveClick() {
      if (!this.validateForm()) return;

      let response = await shiftTemplateServices.cloneShiftTemplates(this.data);

      this.$form.makeToastInfo(response.message);

      this.$emit("updated");
      this.$refs.modal.hide();
    },

    onCloseClick() {
      this.$refs.modal.hide();
    },
    updateDateField(e) {
      this.data.shift_date = e.valueSingleAsString;
    }
  },
  watch: {}
};
</script>

<style></style>
