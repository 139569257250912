var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-container', {
    staticClass: "pb-3",
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', {
    staticClass: "pt-3"
  }, [_c('b-col', [_c('FilteringPanelWrapper', {
    attrs: {
      "model": _vm.model,
      "filters": _vm.filters
    },
    on: {
      "get-data": _vm.onFilteringPanelGetData
    }
  })], 1)], 1), _c('b-row', [_c('b-col', [_c('TableWrapper', {
    ref: _vm.model.name,
    attrs: {
      "model": _vm.model,
      "parent-id": _vm.id,
      "actions-mode": "router",
      "columns": _vm.tableColumns,
      "read-only": false,
      "disable-inline-editing": true,
      "filter-by-column": true,
      "selectable-rows": true
    },
    on: {
      "inserted": _vm.onTableInsert,
      "updated": _vm.onTableChange,
      "row-select": _vm.onRowSelect
    }
  }, [_c('div', {
    attrs: {
      "slot": "afterFilterButtons"
    },
    slot: "afterFilterButtons"
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "size": "sm",
      "disabled": !_vm.selectedRows.length,
      "title": "Duplicate"
    },
    on: {
      "click": function click($event) {
        return _vm.duplicateRecords();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "copy"
    }
  }), _vm._v(" Duplicate ")], 1)], 1)])], 1)], 1), _c('b-row', {
    staticStyle: {
      "margin-bottom": "10em"
    }
  })], 1)], 1), _c('ShiftTemplateCloneModal', {
    ref: "shifts-duplicate-modal",
    on: {
      "updated": _vm.onShiftTemplateCloneModalUpdate
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }