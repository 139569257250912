var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "hide-footer": "",
      "size": "lg",
      "title": _vm.title
    }
  }, [_c('b-card', [_c('b-card-body', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "md": "6"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": _vm.controls.shift_date.id,
      "value-single": _vm.data.shift_date,
      "label": _vm.controls.shift_date.label,
      "readonly": _vm.controls.shift_date.readonly,
      "mode": _vm.$constants.FORM_MODE.EDIT
    },
    on: {
      "changed": _vm.updateDateField
    }
  })], 1)], 1)], 1)], 1), _c('hr'), _c('div', {
    staticClass: "form-row"
  }, [_c('b-col', {
    attrs: {
      "sm": "8"
    }
  }), _c('b-col', {
    staticClass: "d-flex justify-content-end",
    attrs: {
      "sm": "4"
    }
  }, [_c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.onSaveClick();
      }
    }
  }, [_vm.isLoading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e(), !_vm.isLoading ? _c('font-awesome-icon', {
    attrs: {
      "icon": "file"
    }
  }) : _vm._e(), _vm._v(" Save ")], 1), _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.onCloseClick();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "times"
    }
  }), _vm._v(" Close ")], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }